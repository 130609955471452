import _ from 'lodash';

import { CarrierCardType, PriceRangeType } from 'components/CarrierCard';
import { toast } from 'hooks/useToaster';
import DATLogo from 'icons/DAT';
import GreenscreensLogo from 'icons/Greenscreens';
import TruckstopLogo from 'icons/TruckstopLogo';
import { getCustomers } from 'lib/api/getCustomers';
import { getLaneHistory } from 'lib/api/getLaneHistory';
import { getLaneRateFromService } from 'lib/api/getLaneRateFromService';
import { SelectedCarrierType } from 'lib/api/getQuickQuote';
import { getQuoteNumber } from 'lib/api/getQuoteNumber';
import {
  GreenscreensQuote,
  sendGreenscreensQuoteToService,
} from 'lib/api/postGreenscreensQuoteToService';
import {
  UserQuote,
  sendUserQuoteToService,
} from 'lib/api/postUserQuoteToService';
import { submitQuoteToTMS } from 'lib/api/submitQuoteToTMS';
import { submitQuoteViaURL } from 'lib/api/submitQuoteViaURL';
import { updateQuoteRequestSuggestion } from 'lib/api/updateQuoteRequestSuggestion';
import { SuggestionPipelines } from 'types/suggestions/CoreSuggestions';
import { SuggestionStatus } from 'types/suggestions/LoadSuggestions';
import { QuoteChanges } from 'types/suggestions/QuoteSuggestions';

import {
  DATQuoteLocationType,
  DATQuoteTimeframe,
  FetchCustomersProps,
  FetchLaneRateFromServiceProps,
  FetchQuoteNumberProps,
  HandleQuoteSubmissionViaURLProps,
  HelperFunctions,
  MarginType,
  OnSubmitFormProps,
  SendGreenscreensQuoteProps,
  SendUserQuoteProps,
} from './types';

export const useHelperFunctions: HelperFunctions = {
  toTitleCase: (str: string): string => {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  },

  sendGreenscreensQuote: async ({
    email,
    quote,
    setGreenscreensQuoteID,
  }: SendGreenscreensQuoteProps) => {
    if (!email) return;

    const greenscreensQuoteObject: GreenscreensQuote = {
      stops: quote.stops,
      selectedRateName: quote.selectedRateName,
      networkLaneRateDistance: quote.networkLaneRateDistance,
      networkLaneRateTargetBuy: quote.networkLaneRateTargetBuy,
      networkLaneRateConfidenceLevel: quote.networkLaneRateConfidenceLevel,
      laneRateDistance: quote.laneRateDistance,
      laneRateTargetBuy: quote.laneRateTargetBuy,
      laneRateConfidenceLevel: quote.laneRateConfidenceLevel,
    };

    const res = await sendGreenscreensQuoteToService(
      email.id,
      greenscreensQuoteObject
    );
    if (res.isOk()) {
      setGreenscreensQuoteID(res.value);
    }
  },

  sendUserQuote: async ({
    email,
    quote,
    greenscreensQuoteID,
    carrierCost,
    margin,
    marginType,
    finalPrice,
    draftResponse,
  }: SendUserQuoteProps) => {
    if (!greenscreensQuoteID || !email || !quote) return;

    const userQuoteObject: UserQuote = {
      gsQuoteID: greenscreensQuoteID,
      draftResponse: draftResponse,
      carrierCost: carrierCost,
      margin: margin,
      marginType: marginType,
      targetSell: _.round(finalPrice),
      stops: quote.stops,
    };

    await sendUserQuoteToService(email.id, userQuoteObject);
  },

  fetchQuoteNumber: async ({
    email,
    setHasThirdPartyQuoteURLs,
    setValue,
  }: FetchQuoteNumberProps) => {
    if (!email) return;
    const res = await getQuoteNumber(email.id);
    if (res.isOk()) {
      setHasThirdPartyQuoteURLs(res.value.hasThirdPartyQuoteURLs);
      // can't use resetField here because the form input hasn't been initialized yet
      setValue('quoteNumber', res.value.quoteNumber);
      return;
    }
    setHasThirdPartyQuoteURLs(false);
  },

  fetchLaneRateFromService: async ({
    emailId,
    threadId,
    setCarrierCards,
    getValues,
  }: FetchLaneRateFromServiceProps) => {
    const res = await getLaneRateFromService({
      emailId: emailId,
      threadId: threadId,
      transportType: getValues('transportType'),
      originDate: new Date(getValues('pickupDate')).toISOString(),
      originZip: getValues('stops.0.zip'),
      originCity: getValues('stops.0.city'),
      originState: getValues('stops.0.state'),
      destinationDate: new Date(getValues('deliveryDate')).toISOString(),
      destinationZip: getValues('stops.1.zip'),
      destinationCity: getValues('stops.1.city'),
      destinationState: getValues('stops.1.state'),
      equipment: getValues('transportType'),
    });
    if (res.isOk()) {
      const {
        lowPerTrip,
        highPerTrip,
        lowPerMile,
        highPerMile,
        timeframe,
        originName,
        originType,
        destinationName,
        destinationType,
      } = res.value;

      setCarrierCards((prev) => [
        ...prev,
        {
          type: SelectedCarrierType.DAT,
          title: `RateView Median`,
          icon: <DATLogo className='inline-block w-auto h-3' />,
          cost: Number(res.value.ratePerTrip),
          costPerMile: Number(res.value.ratePerMile),
          confidence: null,
          priceRange:
            lowPerTrip && highPerTrip
              ? ({
                  lowEstimate: _.round(lowPerTrip),
                  highEstimate: _.round(highPerTrip),
                } as PriceRangeType)
              : null,
          priceRangePerMile:
            lowPerMile && highPerMile
              ? ({
                  lowEstimate: _.round(lowPerMile),
                  highEstimate: _.round(highPerMile),
                } as PriceRangeType)
              : null,
          tooltipContent: {
            timeframe: DATQuoteTimeframe[timeframe],
            originName: originName,
            originType: DATQuoteLocationType[originType],
            destinationName: destinationName,
            destinationType: DATQuoteLocationType[destinationType],
          },
        },
      ]);
    } else {
      const serviceLaneRateError = res.error.message.includes(
        "Trident's DAT integration"
      )
        ? "Trident's DAT integration failed to get a lane rate."
        : 'Oops, something went wrong while getting the lane rate.';

      toast({
        description: serviceLaneRateError,
        variant: 'destructive',
      });
    }
  },

  fetchCustomers: async ({
    setInitialCustomers,
    setCustomers,
    setTMSTenant,
    tmsIntegrations,
  }: FetchCustomersProps) => {
    const res = await getCustomers(tmsIntegrations?.[0]?.id);
    if (res.isOk()) {
      setInitialCustomers(res.value.customerList);
      setCustomers(res.value.customerList);
      setTMSTenant(res.value.tmsTenant);
    } else {
      toast({
        description: 'Error while fetching customer list.',
        variant: 'destructive',
      });
    }
  },

  onSubmitForm: async ({
    formValues,
    setIsSubmitToTMS,
    setCreatedQuoteId,
    setQuote,
    setCarrierCards,
    isQuoteSubmissionViaURLEnabled,
    email,
    setHasThirdPartyQuoteURLs,
    setValue,
    isGetLaneRateFromServiceEnabled,
    clickedSuggestion,
    formMethods,
    setQuoteNotConfident,
    getQuickQuote,
    isQuoteLaneHistoryEnabled,
    setLaneHistory,
    setCarrierCost,
    setMargin,
    marginType,
    getValues,
  }: OnSubmitFormProps) => {
    // these state variables should be reset when the form is submitted
    setIsSubmitToTMS(false);
    setCreatedQuoteId(undefined);
    setQuote(null);
    setCarrierCards([]);
    setCarrierCost(0);

    // Only need to fetch quote number when submitting quotes via URL is supported
    if (isQuoteSubmissionViaURLEnabled) {
      useHelperFunctions.fetchQuoteNumber({
        email,
        setHasThirdPartyQuoteURLs,
        setValue,
      });
    }

    if (isGetLaneRateFromServiceEnabled) {
      await useHelperFunctions.fetchLaneRateFromService({
        emailId: email?.id ?? 0,
        threadId: email?.threadID ?? '',
        transportType: formValues.transportType,
        setCarrierCards,
        getValues,
      });
    }

    const newQuote = await getQuickQuote(
      email,
      clickedSuggestion,
      formValues,
      formMethods,
      setQuoteNotConfident,
      setMargin,
      marginType
    );

    if (!newQuote) {
      return;
    }

    const newCarrierCards: CarrierCardType[] = [];

    if (newQuote.networkLaneRateTargetBuy) {
      newCarrierCards.push({
        type: SelectedCarrierType.NETWORK,
        title: 'Network Quote',
        icon: (
          <GreenscreensLogo height={12} width={75} className='inline-block' />
        ),
        cost:
          newQuote.networkLaneRateTargetBuy * newQuote.networkLaneRateDistance,
        costPerMile: _.round(newQuote.networkLaneRateTargetBuy, 2),
        confidence: newQuote.networkLaneRateConfidenceLevel,
        priceRange: null,
        tooltipContent: null,
      });
    }

    if (newQuote.laneRateTargetBuy) {
      newCarrierCards.push({
        type: SelectedCarrierType.BUYPOWER,
        title: 'Buy Power Quote',
        icon: (
          <GreenscreensLogo height={12} width={75} className='inline-block' />
        ),
        cost: newQuote.laneRateTargetBuy * newQuote.laneRateDistance,
        costPerMile: _.round(newQuote.laneRateTargetBuy, 2),
        confidence: newQuote.laneRateConfidenceLevel,
        priceRange: null,
        tooltipContent: null,
      });
    }

    if (newQuote.truckStopBookedPredictedRate) {
      newCarrierCards.push({
        type: SelectedCarrierType.TRUCKSTOP_BOOKED,
        title: 'Booked Quote',
        icon: <TruckstopLogo height={12} width={12} className='inline-block' />,
        cost: newQuote.truckStopBookedPredictedRate,
        confidence: newQuote.truckStopBookedAverageScore,
        priceRange: null,
        tooltipContent: null,
      });
    }

    if (newQuote.truckStopPostedPredictedRate) {
      newCarrierCards.push({
        type: SelectedCarrierType.TRUCKSTOP_POSTED,
        title: 'Posted Quote',
        icon: <TruckstopLogo height={12} width={12} className='inline-block' />,
        cost: newQuote.truckStopPostedPredictedRate,
        confidence: newQuote.truckStopPostedAverageScore,
        priceRange: null,
        tooltipContent: null,
      });
    }

    // set state variables
    setMargin(
      marginType === MarginType.Flat
        ? newQuote.defaultFlatMargin || 100
        : newQuote.defaultPercentMargin || 10
    );
    setCarrierCards((prev) => [...prev, ...newCarrierCards]);
    setQuote(newQuote);

    if (isQuoteLaneHistoryEnabled) {
      const res = await getLaneHistory({
        destinationCity: formValues.stops[1].city,
        destinationState: formValues.stops[1].state,
        destinationZip: formValues.stops[1].zip,
        originCity: formValues.stops[0].city,
        originState: formValues.stops[0].state,
        originZip: formValues.stops[0].zip,
        transportType: formValues.transportType,
      });

      if (res.isOk()) {
        setLaneHistory(res.value);
      }
    }
  },

  handleQuoteSubmissionViaURL: async ({
    email,
    quote,
    getValues,
    setError,
    setLoadingDraftReply,
    finalPrice,
    isTMSQuoteSubmissionEnabled,
    isSubmitToTMS,
    setCreatedQuoteId,
    clickedSuggestion,
    setCurrentState,
  }: HandleQuoteSubmissionViaURLProps) => {
    if (!email) return;

    if (!getValues('quoteNumber')) {
      setError(
        'quoteNumber',
        {
          message: 'Quote number is required',
        },
        { shouldFocus: true }
      );
      return;
    }

    if (!finalPrice || finalPrice <= 0) {
      return;
    }

    setLoadingDraftReply(true);

    const res = await submitQuoteViaURL(email.id, {
      quoteAmount: _.round(finalPrice),
      quoteNumber: getValues('quoteNumber'),
      expirationDate: new Date(getValues('quoteExpirationDate')),
      eta: new Date(getValues('quoteEta')),
    });

    if (res.isOk()) {
      toast({
        description: 'Successfully submitted quote via hyperlink.',
        variant: 'success',
      });
    } else {
      toast({
        description: res.error.message,
        variant: 'destructive',
      });
      setLoadingDraftReply(false);
      return; // don't proceed with TMS submission if quote submission via URL fails
    }

    if (isTMSQuoteSubmissionEnabled && isSubmitToTMS) {
      const customerId = getValues('customerName');
      if (!customerId) {
        setLoadingDraftReply(false);
        return;
      }

      const res = await submitQuoteToTMS({
        customerId: customerId.toString(),
        quotePrice: _.round(finalPrice),
        quoteNumber: getValues('quoteNumber'),
        transportType: getValues('transportType'),
        pickupLocationZip: getValues('stops.0.zip'),
        pickupLocationCity: getValues('stops.0.city'),
        pickupLocationState: getValues('stops.0.state'),
        pickupDate: new Date(getValues('pickupDate')).toISOString(),
        deliveryLocationZip: getValues('stops.1.zip'),
        deliveryLocationCity: getValues('stops.1.city'),
        deliveryLocationState: getValues('stops.1.state'),
        deliveryDate: new Date(getValues('deliveryDate')).toISOString(),
      });

      if (res.isOk()) {
        setCreatedQuoteId(res.value.quoteId);
      } else {
        toast({
          description: 'Error creating Quote in TMS.',
          variant: 'destructive',
        });
        setLoadingDraftReply(false);
        return; // don't proceed with suggestion update if TMS submission fails
      }
    }

    // only remove suggestion from list at the very end of a successful quote submission
    if (
      clickedSuggestion &&
      clickedSuggestion.pipeline === SuggestionPipelines.QuickQuote
    ) {
      const quoteInfo: QuoteChanges = {
        transportType: getValues('transportType'),
        pickupZip: getValues('stops.0.zip'),
        pickupCity: getValues('stops.0.city'),
        pickupState: getValues('stops.0.state'),
        pickupDate: new Date(getValues('pickupDate')),
        deliveryZip: getValues('stops.1.zip'),
        deliveryCity: getValues('stops.1.city'),
        deliveryState: getValues('stops.1.state'),
        deliveryDate: new Date(getValues('deliveryDate')),
      };
      await updateQuoteRequestSuggestion(
        clickedSuggestion.id,
        SuggestionStatus.Accepted,
        quoteInfo,
        quote?.id || 0
      );

      setCurrentState((prevState) => ({
        ...prevState,
        clickedSuggestion: null,
        curSuggestionList: prevState.curSuggestionList.filter(
          (s) => s.id !== clickedSuggestion.id
        ),
      }));
    }

    setLoadingDraftReply(false);
  },
};
